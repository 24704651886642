<template>
  <piepkaart
    :minLoadDataZoom="10"
    @popupopen="onPopup"
    name="Verdwenen Nederzettingen"
  >
    <template v-slot="{ bounds, minLoadDataZoom }">
      <piepkaart-wfs-layer
        :bounds="bounds"
        :minLoadDataZoom="minLoadDataZoom"
        wfs-url="https://geo2.piepkaart.nl/geoserver/piepkaart/wfs"
        type-name="piepkaart:verdwenen_nederzettingen"
        output-format="json"
        srs-name="EPSG:4326"
        :pointToLayer="pointToLayer"
        :onEachFeature="onEachFeature"
        :minRefetchDistance="100"
        :use-proxy="false"
      ></piepkaart-wfs-layer>
    </template>
  </piepkaart>
</template>
<script>
import Piepkaart from "@/components/Piepkaart.vue";
import PiepkaartWfsLayer from "@/components/PiepkaartWfsLayer.vue";
import L from "leaflet";
export default {
  components: {
    Piepkaart,
    PiepkaartWfsLayer,
  },
  data() {
    return {
      legend: [
        { color: "#388E3C", label: "Dorp" },
        { color: "#F57C00", label: "Stad" },
        { color: "#D32F2F", label: "Gehucht" },
      ],
    };
  },
  methods: {
    pointToLayer(feature, latlng) {
      return L.circleMarker(latlng, {
        radius: 8,
        stroke: true,
        weight: 1,
        color: "#fff",
        fillColor: "#3B82F6", //#D32F2F
        fillOpacity: 1,
      });
    },
    onEachFeature(feature, layer) {
      if (feature.properties) {
        layer.bindPopup(
          `
          <div class="d-flex">
            <div>
              <div style="font-weight: 600">${feature.properties.naam}</div>
              <div>Soort: ${feature.properties.soort}</div>
              <div>Verdwenen: ${
                feature.properties.jaar_verdw === ""
                  ? "onbekend"
                  : feature.properties.jaar_verdw
              }</div>
              <div>Reden: ${feature.properties.reden}</div>
              <div>Periode: ${feature.properties.periode}</div>
              <div>Herkenningspunt: ${
                feature.properties.herkenning === ""
                  ? "geen"
                  : feature.properties.herkenning
              }</div>
            </div>
          </div>
            `,
          {
            closeButton: false,
            autoPan: false,
          }
        );
      }
    },
    onPopup(popup) {
      const latlng = popup._popup._latlng;
      const location = `${parseFloat(latlng.lat.toFixed(6))},${parseFloat(
        latlng.lng.toFixed(6)
      )}`;
      const { naam } = popup.feature.properties;
      window.gtag("event", "verdwenen-nederzettingen", {
        location: location,
        naam: naam,
      });
    },
  },
};
</script>
